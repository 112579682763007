<template>
    <section class="min-h-screen flex items-center justify-center bg-gray-100">
      <div class="w-full max-w-2xl bg-white rounded-lg shadow-md p-10 mx-auto mt-16">
        <h2 class="text-4xl font-bold text-center text-black">Réinitialisation du mot de passe</h2>
        <p class="mt-6 text-base text-center text-gray-600">
          Entrez votre adresse e-mail pour recevoir un lien de réinitialisation de votre mot de passe.
        </p>
        <form @submit.prevent="resetPassword" class="mt-10 space-y-8">
          <!-- Email Input -->
          <div>
            <label for="email" class="block text-lg font-medium text-gray-900">Adresse e-mail</label>
            <input
              type="email"
              v-model="email"
              id="email"
              placeholder="Entrez votre e-mail"
              class="mt-3 w-full p-4 text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            />
            <p v-if="errors.email" class="text-red-500 text-sm mt-2">{{ errors.email[0] }}</p>
          </div>
          <!-- Submit Button -->
          <div>
            <button
              type="submit"
              :class="{'bg-blue-600 hover:bg-blue-700': !isLoading, 'bg-gray-400': isLoading}"
              class="w-full py-4 text-white text-lg font-medium rounded-lg transition"
              :disabled="isLoading"
            >
              {{ isLoading ? 'Envoi en cours...' : 'Envoyer le lien de réinitialisation' }}
            </button>
          </div>
          <!-- Back to Login -->
          <div class="text-center">
            <a href="/login" class="text-base text-blue-600 hover:text-blue-700 hover:underline">Retour à la connexion</a>
          </div>
        </form>
      </div>
    </section>
  </template>
  
  <script>
  import axios from "@/api/axios.js";
  import { toast } from 'vue3-toastify';
  
  export default {
    data() {
      return {
        email: '',
        isLoading: false,
        errors: {},
      };
    },
    methods: {
      async resetPassword() {
        this.isLoading = true;
        this.errors = {};
  
        try {
          const response = await axios.post('/password/forgot', {
            email: this.email,
          });
  
          if (response.data && response.data.success) {
            // Notification de succès
            toast.success("Un lien de réinitialisation a été envoyé à votre e-mail.", {
              autoClose: 2000,
              position: toast.POSITION.TOP_RIGHT,
            });
  
            // Rediriger vers la page de connexion après un délai
            setTimeout(() => {
              this.$router.push('/login');
            }, 3000);
          }
        } catch (error) {
          if (error.response && error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
  
          // Notification d'erreur générique
          toast.error("Une erreur est survenue. Veuillez vérifier votre e-mail.", {
            autoClose: 2000,
            position: toast.POSITION.TOP_RIGHT,
          });
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
  </script>
  
  <style>
  </style>
  