<template>
<div class="flex min-h-screen">
    <!-- Sidebar -->
    <SideBar />

    <div class="flex-1 p-5">
        <div class="w-full max-w-lg border border-gray-400 rounded-lg p-6">
            <h2 class="text-2xl font-bold text-center mb-4">API Platform Access</h2>

            <!-- Affichage de l'Access Token -->
            <div class="mb-4">
                <label for="access-token" class="block text-sm font-medium text-gray-700">Access Token</label>
                <p id="access-token" class="text-lg font-semibold text-blue-600">{{ accessToken }}</p>
            </div>

            <!-- Affichage de l'Instance ID -->
            <div class="mb-4">
                <label for="instance-id" class="block text-sm font-medium text-gray-700">Instance ID</label>
                <p id="instance-id" class="text-lg font-semibold text-blue-600">{{ instanceId }}</p>
            </div>

            <!-- Générer QR code à partir des informations -->
            <div class="flex justify-center mt-6">
                <canvas ref="qrcodeCanvas" class="border-2 border-gray-300 rounded-lg"></canvas>
            </div>

            <div class="text-center mt-6">
                <p class="text-sm text-gray-600">Scan the QR code to send messages and media via WhatsApp API</p>
            </div>
        </div>
    </div>
</div>
</template>

  
  
<script>
import SideBar from '@/components/SideBar.vue';
import QRCode from "qrcode";

export default {
    components: {
        SideBar, // Déclarez le composant Sidebar ici
    },
    data() {
        return {
            accessToken: this.generateRandomString(),
            instanceId: this.generateRandomString(),
        };
    },
    mounted() {
        const qrData = `Access Token: ${this.accessToken}\nInstance ID: ${this.instanceId}`;
        this.generateQRCode(qrData);
    },
    methods: {
        generateRandomString() {
            return Math.random().toString(36).substr(2, 12);
        },
        generateQRCode(data) {
            QRCode.toCanvas(this.$refs.qrcodeCanvas, data, (error) => {
                if (error) console.error(error);
                console.log("QR Code généré !");
            });
        },
    },
};
</script>
  
  
<style scoped>
canvas {
    max-width: 200px;
    max-height: 200px;
}
</style>
