<template>
<Header />
<section class="py-10 bg-white sm:py-16 lg:py-24">
    <div class="container mx-auto lg:flex lg:space-x-8">
        <!-- Colonne principale pour le contenu de l'article -->
        <div class="w-full lg:w-2/3">
            <h1 class="text-3xl font-bold leading-tight text-black sm:text-4xl">Comment gérer votre équipe à distance ?</h1>
            <p class="mt-2 text-gray-500">Par Martin Jones - 12 juin 2021</p>
            <img src="https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-1.jpg" alt="Image de l'article" class="w-full mt-6 rounded-lg">
            <div class="mt-6 text-gray-700">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque et mauris ac lacus pulvinar feugiat. Sed in nisi non leo fermentum condimentum. Donec vestibulum nunc eget nisl convallis...</p>
                <p class="mt-4">Suspendisse potenti. Morbi et convallis risus, vitae convallis nisl. Nulla facilisi. Donec aliquam turpis non arcu placerat, a scelerisque metus varius. Proin sagittis lectus nec justo fermentum tristique...</p>
                <p class="mt-4">Nam a justo id est egestas vehicula. Phasellus auctor elit sit amet neque volutpat, et faucibus mauris convallis. Donec vel tincidunt mauris...</p>
            </div>
        </div>

        <!-- Colonne latérale pour les derniers articles -->
        <div class="w-full mt-10 lg:mt-0 lg:w-1/3">
            <h2 class="text-xl font-semibold text-gray-800">Derniers articles</h2>

            <div class="mt-6">
                <a href="#" class="flex items-center space-x-4 group">
                    <img src="https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-2.jpg" alt="Image article récent" class="w-20 h-20 rounded-lg object-cover">
                    <div>
                        <p class="font-semibold text-gray-900 group-hover:text-blue-600">6 Emails pour lancer votre produit...</p>
                        <p class="text-sm text-gray-500">10 juin 2021</p>
                    </div>
                </a>
            </div>

            <div class="mt-6">
                <a href="#" class="flex items-center space-x-4 group">
                    <img src="https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-3.jpg" alt="Image article récent" class="w-20 h-20 rounded-lg object-cover">
                    <div>
                        <p class="font-semibold text-gray-900 group-hover:text-blue-600">7 idées d'email marketing à copier...</p>
                        <p class="text-sm text-gray-500">9 juin 2021</p>
                    </div>
                </a>
            </div>

            <div class="mt-6">
                <a href="#" class="flex items-center space-x-4 group">
                    <img src="https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-4.jpg" alt="Image article récent" class="w-20 h-20 rounded-lg object-cover">
                    <div>
                        <p class="font-semibold text-gray-900 group-hover:text-blue-600">Améliorer votre stratégie SEO en 2021</p>
                        <p class="text-sm text-gray-500">8 juin 2021</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>
</template>

  
  
<script>
import Header from '@/components/Header.vue';
export default {
    components: {
        Header
    },
    data() {
        return {

        };
    },
    mounted() {},
    methods: {

    },
};
</script>
  
  
<style scoped>
/* Styles additionnels pour améliorer la présentation */
</style>
