<template>
    <section class="min-h-screen flex items-center justify-center bg-gray-100">
        <div class="w-full max-w-md bg-white rounded-lg shadow-md p-8 mx-auto mt-16">
            <h2 class="text-3xl font-bold text-center text-black">Connectez-vous</h2>
            <p class="mt-4 text-sm text-center text-gray-600">
                Vous n'avez pas de compte ? 
                <a href="/signup" class="text-blue-600 hover:text-blue-700 hover:underline">Créez-en un ici</a>
            </p>
            <form @submit.prevent="loginUser" class="mt-8 space-y-6">
                <div>
                    <label for="email" class="block text-base font-medium text-gray-900">E-mail</label>
                    <input type="email" v-model="email" id="email" placeholder="Votre e-mail" class="mt-2 w-full p-3 text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500" />
                    <p v-if="errors.email" class="text-red-500 text-sm mt-1">{{ errors.email[0] }}</p>
                </div>
                <div>
                    <label for="password" class="block text-base font-medium text-gray-900">Mot de passe</label>
                    <div class="relative">
                        <input :type="showPassword ? 'text' : 'password'" v-model="password" id="password" placeholder="Votre mot de passe" class="mt-2 w-full p-3 pr-10 text-gray-900 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500" />
                        <button type="button" @click="togglePasswordVisibility" class="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none">
                            <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                            </svg>
                        </button>
                    </div>
                    <p v-if="errors.password" class="text-red-500 text-sm mt-1">{{ errors.password[0] }}</p>
                </div>
    
                <div>
                    <button type="submit" :class="{'bg-blue-600 hover:bg-blue-700': !isLoading, 'bg-gray-400': isLoading}" class="w-full py-3 text-white text-lg font-medium rounded-lg transition" :disabled="isLoading">
                        {{ isLoading ? 'Connexion...' : 'Se connecter' }}
                    </button>
                </div>
                <div class="text-center mt-4">
                    <a href="/resset-password" class="text-sm text-blue-600 hover:text-blue-700 hover:underline">Mot de passe oublié ?</a>
                </div>
            </form>
        </div>
    </section>
    </template>
    

<script>
import axios from "@/api/axios.js";
import {
    toast
} from 'vue3-toastify';

export default {
    data() {
        return {
            email: '',
            password: '',
            isLoading: false,
            showPassword: false,
            errors: {}
        };
    },
    methods: {
        async loginUser() {
            this.isLoading = true;
            this.errors = {};

            try {
                const response = await axios.post('/login', {
                    email: this.email,
                    password: this.password,
                });

                if (response.data && response.data.token && response.data.user) {
                    sessionStorage.setItem('authToken', response.data.token);
                    sessionStorage.setItem('userData', JSON.stringify(response.data.user));
                    const route = response.data.user.role_id === 2 ? 'https://mougni.com/organisateur/apercu' : '/admin/dashboard';
                    this.$router.push(route);

                    toast.success("Connexion réussie!", {
                        autoClose: 2000,
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                if (error.response && error.response.data.errors) {
                    this.errors = error.response.data.errors;
                }

                toast.error("Erreur de connexion. Veuillez réessayer.", {
                    autoClose: 2000,
                    position: toast.POSITION.TOP_RIGHT,
                });
            } finally {
                this.isLoading = false;
            }
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        }
    }
};
</script>
