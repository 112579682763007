<template>
    <Header />
    <section class="py-10 bg-white sm:py-16 lg:py-24">
      <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="max-w-2xl mx-auto text-center">
          <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Latest from Blog</h2>
          <p class="max-w-xl mx-auto mt-4 text-base leading-relaxed text-gray-600">
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis.
          </p>
        </div>
  
        <!-- Search and Filter -->
        <div class="flex items-center justify-between mt-10">
          <input v-model="searchQuery" type="text" placeholder="Search articles..." class="w-full p-3 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500 sm:w-1/3" />
          <select v-model="selectedCategory" class="p-3 ml-4 text-sm border rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-500">
            <option value="">All Categories</option>
            <option value="Technology">Technology</option>
            <option value="Marketing">Marketing</option>
          </select>
        </div>
  
        <!-- Blog Posts Grid -->
        <div class="grid max-w-md grid-cols-1 mx-auto mt-12 lg:max-w-full lg:mt-16 lg:grid-cols-3 gap-x-16 gap-y-12">
          <div v-for="post in filteredPosts" :key="post.id">
            <a :href="post.url" title="" class="block aspect-w-4 aspect-h-3">
              <img class="object-cover w-full h-full" :src="post.image" :alt="post.title" />
            </a>
            <span :class="['inline-flex px-4 py-2 text-xs font-semibold tracking-widest uppercase rounded-full mt-9', getCategoryClass(post.category)]">
              {{ post.category }}
            </span>
            <p class="mt-6 text-xl font-semibold">
              <a :href="post.url" title="" class="text-black">{{ post.title }}</a>
            </p>
            <p class="mt-4 text-gray-600">{{ post.description }}</p>
            <div class="h-0 mt-6 mb-4 border-t-2 border-gray-200 border-dashed"></div>
            <span class="block text-sm font-bold tracking-widest text-gray-500 uppercase">{{ post.author }} . {{ post.date }}</span>
          </div>
        </div>
  
        <!-- Pagination Controls -->
        <div class="flex items-center justify-center mt-10 space-x-2">
          <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 text-sm text-white bg-sky-600 rounded-full hover:bg-sky-500 disabled:bg-gray-300">
            &larr; Previous
          </button>
          <span class="px-4 py-2 text-sm font-medium text-gray-700">
            Page {{ currentPage }} of {{ totalPages }}
          </span>
          <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 text-sm text-white bg-sky-600 rounded-full hover:bg-sky-500 disabled:bg-gray-300">
            Next &rarr;
          </button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import Header from "@/components/Header.vue";
  export default {
      components: {
          Header
      },
      data() {
          return {
              searchQuery: '',
              selectedCategory: '',
              blogPosts: [],
              currentPage: 1,
              postsPerPage: 6,
          };
      },
      computed: {
          filteredPosts() {
              let filtered = this.blogPosts.filter(post =>
                  post.title.toLowerCase().includes(this.searchQuery.toLowerCase()) &&
                  (!this.selectedCategory || post.category === this.selectedCategory)
              );
              const start = (this.currentPage - 1) * this.postsPerPage;
              const end = start + this.postsPerPage;
              return filtered.slice(start, end);
          },
          totalPages() {
              return Math.ceil(this.blogPosts.length / this.postsPerPage);
          },
      },
      methods: {
          fetchPosts() {
              this.blogPosts = [
                  { id: 1, title: 'How to manage your remote team?', category: 'Technology', author: 'Martin Jones', date: 'June 12, 2021', description: 'Amet minim mollit...', image: 'https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-1.jpg', url: '#' },
                  { id: 2, title: '6 Product launching emails...', category: 'Marketing', author: 'Martin Jones', date: 'June 12, 2021', description: 'Amet minim mollit...', image: 'https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-2.jpg', url: '#' },
                  { id: 3, title: 'Learn from the best: 7 email marketing ideas...', category: 'Marketing', author: 'Martin Jones', date: 'June 12, 2021', description: 'Amet minim mollit...', image: 'https://cdn.rareblocks.xyz/collection/celebration/images/blog/1/blog-post-3.jpg', url: '#' },
              ];
          },
          getCategoryClass(category) {
              switch (category) {
                  case 'Technology':
                      return 'text-rose-500 bg-rose-100';
                  case 'Marketing':
                      return 'text-sky-500 bg-sky-100';
                  default:
                      return 'text-gray-500 bg-gray-100';
              }
          },
          nextPage() {
              if (this.currentPage < this.totalPages) {
                  this.currentPage++;
              }
          },
          prevPage() {
              if (this.currentPage > 1) {
                  this.currentPage--;
              }
          },
      },
      mounted() {
          this.fetchPosts();
      },
  };
  </script>
  
  <style scoped>
  /* Additional styles for pagination */
  button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
  }
  </style>
  