<template>
<div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
    <div class="flex items-center justify-between h-16 lg:h-20">
        <!-- Logo -->
        <div class="flex-shrink-0">
            <a href="/" title="Accueil" class="flex">
                <img class="w-auto h-8" src="/img/logo_header.png" alt="Logo" />
            </a>
        </div>

        <!-- Bouton menu mobile -->
        <button @click="isMenuOpen = !isMenuOpen" type="button" class="inline-flex p-2 text-black transition-all duration-200 rounded-md lg:hidden focus:bg-gray-100 hover:bg-gray-100">
            <!-- Icône menu -->
            <svg v-if="!isMenuOpen" class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16" />
            </svg>
            <!-- Icône fermeture -->
            <svg v-else class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>

        <!-- Menu mobile -->
        <div v-if="isMenuOpen" class="absolute top-16 left-0 w-full bg-[#FCF8F1] bg-opacity-90 p-4 space-y-4 lg:hidden">
            <a href="/" title="Accueil" class="block text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Accueil
            </a>
            <a href="/jyserai/badges" title="Badges" class="block text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Badges J'y serai
            </a>
            <a href="/evenements" title="Badges" class="block text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Event-Badges
            </a>
            <a href="/blogs" title="Blog" class="block text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Blog
            </a>
            <a href="/pricing" title="Tarifs" class="block text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Tarifs
            </a>
            <a href="#" title="Contact" class="block text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Contact
            </a>
        </div>

        <!-- Menu desktop -->
        <div class="hidden lg:flex lg:items-center lg:space-x-10">
            <a href="/" title="Accueil" class="text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Accueil
            </a>
            <a href="/jyserai/badges" title="Badges" class="block text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Badges J'y serai
            </a>
            <a href="/evenements" title="Badges" class="block text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Event-Badges
            </a>
            <a href="/blogs" title="Blog" class="text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Blog
            </a>
            <a href="/pricing" title="Tarifs" class="text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Tarifs
            </a>
            <a href="#" title="Contact" class="text-base font-bold text-black transition-all duration-200 hover:text-opacity-80">
                Contact
            </a>
        </div>

        <!-- Bouton call-to-action -->
        <a href="/login" title="Rejoindre maintenant" class="hidden lg:inline-flex items-center justify-center px-5 py-2.5 text-base transition-all duration-200 hover:bg-yellow-300 hover:text-black focus:text-black focus:bg-yellow-300 font-semibold text-white bg-black rounded-full" role="button">
            Rejoindre maintenant
        </a>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            isMenuOpen: false, 
        };
    },
};
</script>

<style>
/* Ajouter ici des styles personnalisés si nécessaire */
</style>
