<template>
<section>
    <Header />
    <div class="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
        <h2 class="text-center text-3xl font-bold md:text-5xl">J'y serai</h2>

        <div class="mt-6 flex justify-center">
            <input type="text" v-model="searchQuery" placeholder="Rechercher un badge..." class="w-full max-w-md px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-yellow-500" />
        </div>

        <div class="mt-6">
            <div v-if="isLoading" class="flex justify-center items-center">
                <div class="w-12 h-12 border-4 border-yellow-500 border-t-transparent rounded-full animate-spin"></div>
            </div>

            <div v-else class="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                <div v-for="event in filteredBadges" :key="event.badge.id" class="flex flex-col items-center gap-4 rounded-lg border border-gray-200 bg-white shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
                    <a :href="'/badges/generate-badges/' + 'jyserai/' + event.badge.id" class="mb-4 inline-block h-52 w-full overflow-hidden rounded-lg">
                        <img :src="BACK_URL + event.badge.badge" alt="" class="h-full w-full object-cover rounded-lg transition-transform duration-300 transform hover:scale-105" />
                    </a>
                    <p class="font-bold text-xl text-gray-800">
                        {{ event.badge.name.length > 16 ? event.badge.name.slice(0, 16) + '...' : event.badge.name }}
                    </p>

                    <div class="text-sm text-gray-500">
                        <span>Vues: {{ event.badge.views }}</span> |
                        <span>Téléchargements: {{ event.badge.downloads }}</span>
                    </div>
                    <a :href="'/badges/generate-badges/' + 'jyserai/' + event.badge.id" class="inline-flex items-center px-6 py-2 font-semibold text-black transition-all duration-200 bg-yellow-500 rounded-full hover:bg-yellow-600 focus:bg-yellow-600 mt-4">
                        Voir le Badge
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Header from "@/components/Header.vue";
import axios from "@/api/axios.js";
import {
    BACK_URL
} from "@/event_badge.js";

export default {
    components: {
        Header
    },
    data() {
        return {
            eventsbadges: [],
            isLoading: true,
            searchQuery: "",
            BACK_URL: BACK_URL,
        };
    },
    computed: {
        filteredBadges() {
            return this.eventsbadges.filter(event =>
                event.badge.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        }
    },
    mounted() {
        this.fetchEventsBadges();
    },
    methods: {
        fetchEventsBadges() {
            this.isLoading = true;
            axios.get('/event')
                .then(response => {
                    this.eventsbadges = response.data.badges;
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des événements!", error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
}
</script>
