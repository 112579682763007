<template>
<section class="min-h-screen flex items-center justify-center bg-gray-100">
    <div class="w-full max-w-md bg-white rounded-lg shadow-md p-8">
        <h2 class="text-3xl font-bold text-center text-black">Inscrivez-vous</h2>

        <p class="mt-4 text-base text-gray-600 text-center">
            Vous avez déjà un compte ?
            <a href="/login" title="" class="font-medium text-blue-600 transition-all duration-200 hover:text-blue-700 hover:underline focus:text-blue-700">Connexion</a>
        </p>

        <form @submit.prevent="register" class="mt-6">
            <div class="space-y-6">
                <!-- Nom -->
                <div>
                    <label for="nom" class="text-base font-medium text-gray-900">Nom</label>
                    <div class="mt-2.5">
                        <input type="text" name="nom" id="nom" v-model="form.first_name" placeholder="Entrez votre nom" class="block w-full max-w-md p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600" />
                        <span v-if="errors.first_name" class="text-red-500 text-sm">{{ errors.first_name }}</span>
                    </div>
                </div>

                <!-- Prénom -->
                <div>
                    <label for="prenom" class="text-base font-medium text-gray-900">Prénom</label>
                    <div class="mt-2.5">
                        <input type="text" name="prenom" id="prenom" v-model="form.last_name" placeholder="Entrez votre prénom" class="block w-full max-w-md p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600" />
                        <span v-if="errors.last_name" class="text-red-500 text-sm">{{ errors.last_name }}</span>
                    </div>
                </div>

                <!-- Téléphone -->
                <div>
                    <label for="telephone" class="text-base font-medium text-gray-900">Téléphone</label>
                    <div class="mt-2.5 flex">
                        <span class="inline-flex items-center px-3 text-gray-600 bg-gray-200 border border-r-0 border-gray-200 rounded-l-md">+{{ callingCode }}</span>
                        <input type="tel" id="telephone" v-model="form.phone" placeholder="Entrez votre numéro de téléphone" @input="validatePhoneInput" class="block w-full max-w-md p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-r-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600" />
                        <span v-if="errors.phone" class="text-red-500 text-sm">{{ errors.phone }}</span>
                    </div>
                </div>

                <!-- WhatsApp -->
                <div>
                    <label for="whatsapp" class="text-base font-medium text-gray-900">Numéro WhatsApp</label>
                    <div class="mt-2.5 flex">
                        <span class="inline-flex items-center px-3 text-gray-600 bg-gray-200 border border-r-0 border-gray-200 rounded-l-md">+{{ callingCode }}</span>
                        <input type="tel" id="whatsapp" v-model="form.whatsapp" placeholder="Entrez votre numéro WhatsApp" @input="validateWhatsAppInput" class="block w-full max-w-md p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-r-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600" />
                        <span v-if="errors.whatsapp" class="text-red-500 text-sm">{{ errors.whatsapp }}</span>
                    </div>
                </div>

                <!-- Email -->
                <div>
                    <label for="email" class="text-base font-medium text-gray-900">Adresse e-mail</label>
                    <div class="mt-2.5">
                        <input type="email" name="email" id="email" v-model="form.email" placeholder="Entrez votre adresse e-mail" class="block w-full max-w-md p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600" />
                        <span v-if="errors.email" class="text-red-500 text-sm">{{ errors.email }}</span>
                    </div>
                </div>

                <!-- Mot de passe -->
                <div>
                    <label for="password" class="text-base font-medium text-gray-900">Mot de passe</label>
                    <div class="mt-2.5">
                        <input type="password" name="password" id="password" v-model="form.password" placeholder="Entrez votre mot de passe" class="block w-full max-w-md p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600" />
                        <span v-if="errors.password" class="text-red-500 text-sm">{{ errors.password }}</span>
                    </div>
                </div>

                <!-- Confirmation du mot de passe -->
                <div>
                    <label for="confirm_password" class="text-base font-medium text-gray-900">Confirmation du mot de passe</label>
                    <div class="mt-2.5">
                        <input type="password" name="confirm_password" id="confirm_password" v-model="form.password_confirmation" placeholder="Confirmez votre mot de passe" class="block w-full max-w-md p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600" />
                        <span v-if="errors.password_confirmation" class="text-red-500 text-sm">{{ errors.password_confirmation }}</span>
                    </div>
                </div>
            </div>

            <!-- Conditions d'utilisation -->
            <div class="flex items-center mt-6">
                <input type="checkbox" name="agree" id="agree" v-model="form.agree" class="w-5 h-5 text-blue-600 bg-white border-gray-200 rounded" />
                <label for="agree" class="ml-3 text-sm font-medium text-gray-500">
                    J'accepte les <a href="/term-condition" title="" class="text-blue-600 hover:text-blue-700 hover:underline">Conditions d'utilisation</a> et la <a href="/privacy" title="" class="text-blue-600 hover:text-blue-700 hover:underline">Politique de confidentialité</a>
                </label>
            </div>

            <!-- Bouton d'inscription -->
            <div class="mt-6">
                <button type="submit" class="inline-flex items-center justify-center w-full px-6 py-4 text-lg font-semibold text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-md focus:outline-none hover:bg-blue-700 focus:bg-blue-700">
                    S'inscrire
                </button>
            </div>
        </form>
    </div>
</section>
</template>

<script>
import axios from "@/api/axios.js";
import {
    toast
} from 'vue3-toastify';

export default {
    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                phone: '',
                whatsapp: '',
                email: '',
                password: '',
                password_confirmation: '',
                continent: '',
                country: '',
                city: ''
            },
            isLoading: false,
            callingCode: '',
            passwordVisible: false,
            errors: {}
        };
    },
    methods: {
        async fetchCallingCode() {
            try {
                const response = await axios.get('https://ipwho.is/');
                this.callingCode = response.data.calling_code || '';
                this.form.continent = response.data.continent || '';
                this.form.country = response.data.country || '';
                this.form.city = response.data.city || '';
            } catch (error) {
                this.callingCode = '';
                this.form.continent = '';
                this.form.country = '';
                this.form.city = '';
            }
        },

        async register() {
            this.isLoading = true;

            let fullPhoneNumber = `${this.callingCode}${this.form.phone}`;
            let fullWhatsAppNumber = `${this.callingCode}${this.form.whatsapp}`;

            if (this.callingCode === '229') {
                if (this.form.phone.length === 8) {
                    fullPhoneNumber = `22901${this.form.phone}`;
                }
                if (this.form.whatsapp.length === 8) {
                    fullWhatsAppNumber = `22901${this.form.whatsapp}`;
                }
            }

            try {
                const registrationForm = {
                    ...this.form,
                    phone: fullPhoneNumber,
                    whatsapp: fullWhatsAppNumber
                };

                const response = await axios.post('/register', registrationForm);

                if (response.data.status) {
                    toast.success("Inscription réussie", {
                        autoClose: 3000,
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    this.$router.push('/login');
                }
            } catch (error) {
                if (error.response && error.response.data.errors) {
                    this.errors = error.response.data.errors;

                    toast.error("Renseignez tous les champs, réessayez.", {
                        autoClose: 2000,
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error("Une erreur est survenue, veuillez réessayer.", {
                        autoClose: 2000,
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } finally {
                this.isLoading = false;
            }
        },

        togglePasswordVisibility(inputId) {
            this.passwordVisible = !this.passwordVisible;
            const input = document.getElementById(inputId);
            input.type = this.passwordVisible ? 'text' : 'password';
        },
        validatePhoneInput(event) {
            const input = event.target.value;
            if (/^\d*$/.test(input)) {
                this.form.phone = input;
            } else {
                this.form.phone = this.form.phone.replace(/\D/g, '');
            }
        },
        validateWhatsAppInput(event) {
            const input = event.target.value;
            if (/^\d*$/.test(input)) {
                this.form.whatsapp = input;
            } else {
                this.form.whatsapp = this.form.whatsapp.replace(/\D/g, '');
            }
        }
    },
    mounted() {
        this.fetchCallingCode();
    }
};
</script>

<style>

</style>
