<template>
    <div>
      <!-- Header Section -->
      <div class="bg-white">
        <Header />
      </div>
      
      <!-- Main Banner Section -->
      <section class="bg-[#FCF8F1] bg-opacity-30 py-10 sm:py-16 lg:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            <div>
              <h1 class="mt-4 text-4xl font-bold text-black lg:mt-8 sm:text-6xl xl:text-8xl">
                Mougni Ticket 🎫
              </h1>
              <p class="mt-4 text-xl text-black">
                Gérez et vendez vos billets via WhatsApp de manière simple et efficace.
              </p>
              <a href="/login" class="inline-flex items-center px-6 py-4 mt-8 font-semibold text-black transition-all duration-200 bg-yellow-300 rounded-full lg:mt-16 hover:bg-yellow-400 focus:bg-yellow-400">
                Commencez maintenant
                <svg class="w-6 h-6 ml-8 -mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </a>
            </div>
            <div>
              <img class="w-full" src="/img/mougni_ticket.png" alt="Mougni Ticket" />
            </div>
          </div>
        </div>
      </section>
  
      <!-- Features Section -->
      <section class="bg-white py-16 sm:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 class="text-3xl font-semibold text-center text-black mb-12">Pourquoi choisir Mougni Ticket ?</h2>
          <div class="grid gap-12 sm:grid-cols-2 lg:grid-cols-3">
            <!-- Feature 1 -->
            <div class="text-center">
              <img class="w-16 h-16 mx-auto mb-4" src="/img/feature1.svg" alt="Vente de billets">
              <h3 class="text-xl font-bold text-black">Vente de Billets Facile</h3>
              <p class="text-gray-600 mt-4">Vendez vos billets rapidement via WhatsApp. Plus besoin de passer par des plateformes complexes.</p>
            </div>
            <!-- Feature 2 -->
            <div class="text-center">
              <img class="w-16 h-16 mx-auto mb-4" src="/img/feature2.svg" alt="Gestion en temps réel">
              <h3 class="text-xl font-bold text-black">Gestion en Temps Réel</h3>
              <p class="text-gray-600 mt-4">Suivez vos ventes et gérez vos billets en temps réel via WhatsApp, directement sur votre téléphone.</p>
            </div>
            <!-- Feature 3 -->
            <div class="text-center">
              <img class="w-16 h-16 mx-auto mb-4" src="/img/feature3.svg" alt="Sécurisé">
              <h3 class="text-xl font-bold text-black">Sécurisé</h3>
              <p class="text-gray-600 mt-4">Vos transactions sont sécurisées grâce à un système de paiement intégré et de notifications instantanées.</p>
            </div>
          </div>
        </div>
      </section>
  
      <!-- How It Works Section -->
      <section class="bg-[#FCF8F1] bg-opacity-30 py-16 sm:py-24">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 class="text-3xl font-semibold text-center text-black mb-12">Comment ça fonctionne ?</h2>
          <div class="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            <!-- Step 1 -->
            <div class="text-center">
              <img class="w-16 h-16 mx-auto mb-4" src="/img/step1.svg" alt="Création d'événement">
              <h3 class="text-xl font-bold text-black">Créez Votre Événement</h3>
              <p class="text-gray-600 mt-4">Créez votre événement en quelques clics et générez votre page de vente de billets sur WhatsApp.</p>
            </div>
            <!-- Step 2 -->
            <div class="text-center">
              <img class="w-16 h-16 mx-auto mb-4" src="/img/step2.svg" alt="Distribution des billets">
              <h3 class="text-xl font-bold text-black">Distribuez les Billets</h3>
              <p class="text-gray-600 mt-4">Envoyez des liens ou des QR codes aux participants via WhatsApp pour acheter leurs billets facilement.</p>
            </div>
            <!-- Step 3 -->
            <div class="text-center">
              <img class="w-16 h-16 mx-auto mb-4" src="/img/step3.svg" alt="Suivi des ventes">
              <h3 class="text-xl font-bold text-black">Suivez les Ventes</h3>
              <p class="text-gray-600 mt-4">Suivez en temps réel vos ventes et gérez la distribution des billets directement sur WhatsApp.</p>
            </div>
          </div>
        </div>
      </section>
  
      <!-- Testimonials Section -->
      <Testinomial />
  
      <!-- Footer Section -->
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue';
  import Footer from '@/components/Footer.vue';
  import Testinomial from '@/components/Testinomial.vue';
  
  export default {
    components: {
      Header,
      Footer,
      Testinomial
    },
  };
  </script>
  
  <style scoped>

  </style>
  