<template>
<div class="flex flex-col items-center">
    <div class="mx-auto my-8 max-w-3xl px-4">
        <h1 class="text-2xl font-bold mb-4 text-center">{{ event.name }}</h1>
        <div id="badge-container" class="flex justify-center">
            <canvas id="badge-canvas"></canvas>
        </div>

        <br />
        <div class="flex justify-between mt-4">
            <label for="photo-upload" class="inline-flex items-center justify-center w-30 px-7 py-2 bg-yellow-500 text-white text-sm rounded-md cursor-pointer hover:bg-yellow-600">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-5 mr-2">
                    <path fill-rule="evenodd" d="M1 8a2 2 0 0 1 2-2h.93a2 2 0 0 0 1.664-.89l.812-1.22A2 2 0 0 1 8.07 3h3.86a2 2 0 0 1 1.664.89l.812 1.22A2 2 0 0 0 16.07 6H17a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8Zm13.5 3a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM10 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd" />
                </svg>
                Votre Photo
                <input type="file" id="photo-upload" class="hidden" accept="image/*" />
            </label>

            <button @click="handleDownload" :disabled="isDownloading" class="inline-flex items-center justify-center w-30 px-7 py-2 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-5 mr-2">
                    <path fill-rule="evenodd" d="M4.5 2A1.5 1.5 0 0 0 3 3.5v13A1.5 1.5 0 0 0 4.5 18h11a1.5 1.5 0 0 0 1.5-1.5V7.621a1.5 1.5 0 0 0-.44-1.06l-4.12-4.122A1.5 1.5 0 0 0 11.378 2H4.5Zm4.75 6.75a.75.75 0 0 1 1.5 0v2.546l.943-1.048a.75.75 0 0 1 1.114 1.004l-2.25 2.5a.75.75 0 0 1-1.114 0l-2.25-2.5a.75.75 0 1 1 1.114-1.004l.943 1.048V8.75Z" clip-rule="evenodd" />
                </svg>
                <span v-if="isDownloading"> En cours....</span>
                <span v-else>Télécharger</span>
            </button>
        </div>

        <br />

        <div class="flex justify-between mt-4">
            <input type="range" id="zoom-slider" min="0.5" max="3" step="0.1" v-model="scale" @input="handleZoom" style="width: 50%;" />

            <button @click="openShareModal" class="inline-flex items-center px-10 py-2 bg-red-500 text-white text-sm rounded-md hover:bg-red-600">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="size-5 mr-2">
                    <path d="M13 4.5a2.5 2.5 0 1 1 .702 1.737L6.97 9.604a2.518 2.518 0 0 1 0 .792l6.733 3.367a2.5 2.5 0 1 1-.671 1.341l-6.733-3.367a2.5 2.5 0 1 1 0-3.475l6.733-3.366A2.52 2.52 0 0 1 13 4.5Z" />
                </svg>
                Partager
            </button>

        </div>

        <div v-if="isShareModalOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white rounded-lg shadow-lg p-6 w-80">
                <h2 class="text-xl font-semibold mb-4">Partager sur :</h2>
                <div class="flex flex-col space-y-4">
                    <button @click="shareOnWhatsApp" class="inline-flex items-center px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" class="mr-2">
                            <path d="M12.02 2c-5.52 0-10 4.48-10 10 0 1.75.46 3.47 1.35 5.01L2 22l4.99-1.33c1.5.87 3.21 1.33 4.96 1.33 5.52 0 10-4.48 10-10s-4.48-10-10-10zm5.14 14.36c-.21.6-1.21 1.15-1.69 1.18-.44.03-.84.21-2.74-.58-2.3-.9-3.74-3.21-3.86-3.35-.11-.15-.92-1.22-.92-2.34 0-1.11.58-1.66.79-1.88.21-.22.45-.28.6-.28.15 0 .3.01.43.01.14.01.33-.05.51.39.19.44.66 1.57.72 1.68.06.11.1.24.02.39-.07.15-.11.23-.22.36-.11.13-.22.28-.32.38-.11.11-.23.23-.1.45.14.22.62 1.03 1.32 1.66.91.82 1.67 1.07 1.89 1.18.22.11.35.09.48-.05.13-.14.54-.63.69-.85.15-.22.29-.19.48-.11.2.08 1.27.6 1.48.71.22.11.36.16.42.25.05.08.05.59-.16 1.19z" />
                        </svg>
                        WhatsApp
                    </button>

                    <button @click="shareOnTwitter" class="inline-flex items-center px-4 py-2 bg-blue-400 text-white rounded-md hover:bg-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" class="mr-2">
                            <path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.52 1.56-1.35 1.88-2.33-.83.49-1.76.83-2.75 1.02a4.18 4.18 0 0 0-7.12 3.81 11.88 11.88 0 0 1-8.63-4.37 4.17 4.17 0 0 0 1.29 5.56c-.69-.02-1.35-.21-1.92-.52v.05a4.18 4.18 0 0 0 3.34 4.1c-.66.18-1.36.22-2.08.08a4.2 4.2 0 0 0 3.9 2.89 8.38 8.38 0 0 1-5.18 1.79A11.9 11.9 0 0 0 12 21c7.68 0 11.88-6.37 11.88-11.88 0-.18 0-.36-.01-.54a8.47 8.47 0 0 0 2.09-2.16z" />
                        </svg>
                        Twitter
                    </button>
                    <button @click="shareOnFacebook" class="inline-flex items-center px-4 py-2 bg-blue-800 text-white rounded-md hover:bg-blue-900">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" class="mr-2">
                            <path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.99 3.66 9.12 8.44 9.88v-6.99H7.9v-2.89h2.54V9.59c0-2.51 1.5-3.89 3.78-3.89 1.1 0 2.25.2 2.25.2v2.47h-1.27c-1.25 0-1.63.78-1.63 1.57v1.9h2.78l-.44 2.89h-2.34V22C18.34 21.12 22 16.99 22 12z" />
                        </svg>
                        Facebook
                    </button>
                </div>
                <div class="flex justify-end mt-4">
                    <button @click="closeShareModal" class="text-gray-500 hover:text-gray-700">Fermer</button>
                </div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
import axios from "@/api/axios.js";
import {
    toast
} from 'vue3-toastify';
import {
    v4 as uuidv4
} from 'uuid';
import {
    BACK_URL
} from '@/event_badge.js';

function createSlug(str) {
    return str.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');
}

export default {
    data() {
        return {
            BACK_URL: BACK_URL,
            isDownloading: false,
            img: new Image(),
            templateImg: new Image(),
            scale: 1,
            imgX: 0,
            imgY: 0,
            isDragging: false,
            lastX: 0,
            lastY: 0,
            event: {},
            isShareModalOpen: false,
            isDownloading: false,
        };
    },
    mounted() {
        const badgeId = this.$route.params.id;

        axios.post(`/event_badges/${badgeId}/increment-views`)
            .then(response => {
                console.log(response.data.message);
            })
            .catch(error => {
                console.error('Erreur lors de l\'incrémentation des vues:', error);
            });

        this.setupCanvas();
        this.loadTemplateImage(badgeId);

        // Appeler la méthode pour traquer la visite
        this.trackVisit();

        document.getElementById('photo-upload').addEventListener('change', this.handleFileUpload);
        document.getElementById('zoom-slider').addEventListener('input', this.handleZoom);
    },
    methods: {
        formatDate(dateTime) {
            if (!dateTime) return '';
            return dateTime.split(' ')[0];
        },
        setupCanvas() {
            const canvas = document.getElementById('badge-canvas');
            const ctx = canvas.getContext('2d');
            const badgeContainer = document.getElementById('badge-container');

            canvas.width = badgeContainer.clientWidth * 2;
            canvas.height = badgeContainer.clientHeight * 2;
            canvas.style.width = `${badgeContainer.clientWidth}px`;
            canvas.style.height = `${badgeContainer.clientHeight}px`;
            ctx.scale(2, 2);

            canvas.addEventListener('mousedown', this.handleInteractionStart);
            canvas.addEventListener('mousemove', this.handleInteractionMove);
            canvas.addEventListener('mouseup', this.handleInteractionEnd);
            canvas.addEventListener('touchstart', this.handleInteractionStart);
            canvas.addEventListener('touchmove', this.handleInteractionMove);
            canvas.addEventListener('touchend', this.handleInteractionEnd);
        },
        loadTemplateImage(badgeId) {
            axios.get(`/event/${badgeId}`)
                .then(response => {
                    const event = response.data.event;
                    const badgeImagePath = `${this.BACK_URL}${event.badge}`;
                    this.templateImg.src = badgeImagePath;
                    this.templateImg.onload = this.drawImage;
                    this.event = event;
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des détails de l\'événement:', error);
                });
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                this.img.src = e.target.result;
                this.img.onload = this.drawImage;
            };
            reader.readAsDataURL(file);
        },
        handleZoom(event) {
            this.scale = parseFloat(event.target.value);
            this.drawImage();
        },
        drawImage() {
            const canvas = document.getElementById('badge-canvas');
            const ctx = canvas.getContext('2d');

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const imgWidth = 350 * this.scale;
            const imgHeight = (imgWidth * this.img.height) / this.img.width;
            ctx.drawImage(this.img, this.imgX, this.imgY, imgWidth, imgHeight);
            ctx.drawImage(this.templateImg, 0, 0, canvas.width / 2, canvas.height / 2);

        },
        handleInteractionStart(event) {
            event.preventDefault();
            const canvas = document.getElementById('badge-canvas');
            const rect = canvas.getBoundingClientRect();
            const x = (event.clientX || event.touches[0].clientX) - rect.left;
            const y = (event.clientY || event.touches[0].clientY) - rect.top;

            this.isDragging = true;
            this.lastX = x;
            this.lastY = y;
        },
        handleInteractionMove(event) {
            event.preventDefault();
            if (this.isDragging) {
                const canvas = document.getElementById('badge-canvas');
                const rect = canvas.getBoundingClientRect();
                const x = (event.clientX || event.touches[0].clientX) - rect.left;
                const y = (event.clientY || event.touches[0].clientY) - rect.top;

                this.imgX += x - this.lastX;
                this.imgY += y - this.lastY;
                this.lastX = x;
                this.lastY = y;

                this.drawImage();
            }
        },
        handleInteractionEnd(event) {
            event.preventDefault();
            this.isDragging = false;
        },
        handleDownload() {
            this.isDownloading = true;
            const badgeId = this.$route.params.id;

            axios.get(`/event/${badgeId}`)
                .then(response => {
                    const event = response.data.event;
                    const title = event.name;
                    const slug = createSlug(title);
                    const uniqueCode = uuidv4();

                    let maxDownloadsAllowed = 0;
                    switch (event.option) {
                        case 'Premium':
                            maxDownloadsAllowed = Infinity;
                            break;
                        case 'Freemium':
                            maxDownloadsAllowed = 15;
                            break;
                        case 'Standard':
                            maxDownloadsAllowed = 125;
                            break;
                        default:
                            maxDownloadsAllowed = 0;
                            break;
                    }

                    if (event.downloads < maxDownloadsAllowed) {
                        const link = document.createElement('a');
                        link.download = `badge_${slug}_${uniqueCode}_by@Mougni.com.png`;
                        link.href = document.getElementById('badge-canvas').toDataURL();
                        link.click();

                        axios.post(`/event_badges/${event.id}/increment-downloads`)
                            .then(response => {
                                console.log(response.data.message);
                            })
                            .catch(error => {
                                console.error('Erreur lors de l\'incrémentation des téléchargements:', error);
                            });
                    } else {
                        toast.error(`Téléchargement atteinte pour l'option "${event.option}". Contactez le promoteur.`, {
                            autoClose: 3000,
                            position: toast.POSITION.TOP_RIGHT,
                        });

                    }
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des détails de l\'événement:', error);
                })
                .finally(() => {
                    this.isDownloading = false;
                });
        },
        shareOnWhatsApp() {
            const badgeId = this.$route.params.id;
            const url = `${window.location.origin}/badges/generate-badges/${badgeId}`;
            const text = encodeURIComponent(`Génère ton badge j'y serai via ce lien : ${url} pour montrer ton soutien à/au ${this.event.name}`);
            window.open(`https://wa.me/?text=${text}`, '_blank');
        },
        shareOnTwitter() {
            const badgeId = this.$route.params.id;
            const url = `${window.location.origin}/badges/generate-badges/${badgeId}`;
            const text = encodeURIComponent(`Génère ton badge j'y serai via ce lien : ${url} pour montrer ton soutien à/au ${this.event.name}`);
            window.open(`https://twitter.com/intent/tweet?text=${text}&url=${url}`, '_blank');
        },
        shareOnFacebook() {
            const badgeId = this.$route.params.id;
            const url = `${window.location.origin}/badges/generate-badges/${badgeId}`;
            const text = encodeURIComponent(`Génère ton badge j'y serai via ce lien : ${url} pour montrer ton soutien à/au ${this.event.name}`);
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`, '_blank');
        },

        openShareModal() {
            this.isShareModalOpen = true;
        },
        closeShareModal() {
            this.isShareModalOpen = false;
        },
        trackVisit() {
            const badgeId = this.$route.params.id;

            axios.get('https://ipwho.is/')
                .then(response => {
                    const ipData = response.data;

                    // Construire les données à envoyer
                    const visitData = {
                        event_badge_id: badgeId,
                        ip: ipData.ip,
                        type: ipData.type,
                        continent: ipData.continent,
                        continent_code: ipData.continent_code,
                        country: ipData.country,
                        country_code: ipData.country_code,
                        region: ipData.region,
                        region_code: ipData.region_code,
                        city: ipData.city,
                        latitude: ipData.latitude,
                        longitude: ipData.longitude,
                        calling_code: ipData.calling_code,
                        capital: ipData.capital,
                        flag: ipData.flag.img
                    };

                    // Envoyer les données au serveur
                    axios.post('/event-badge-visits', visitData)
                        .then(postResponse => {
                            console.log('Visite enregistrée avec succès:', postResponse.data.message);
                        })
                        .catch(postError => {
                            console.error('Erreur lors de l\'enregistrement de la visite:', postError);
                        });
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des informations IP:', error);
                });
        }
    }

};
</script>

<style>
#badge-container {
    position: relative;
    width: 29rem;
    height: 29rem;
}

#badge-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

@media (max-width: 576px) {
    #badge-container {
        width: 20rem;
        height: 20rem;
    }
}
</style>

<style>

</style>
