import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://horusweb3.xyz/api/public/api',

 // baseURL: '  http://localhost:8000/api',


});

// Interceptor pour les réponses
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // Gérer les erreurs
    console.error('Erreur:', error);
    alert(error.response?.data?.message || 'Une erreur est survenue');
    return Promise.reject(error);
  }
);

export default axiosInstance;
