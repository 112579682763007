<template>
    <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-2xl mx-auto text-center">
          <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Prix & Plans</h2>
          <p class="max-w-md mx-auto mt-4 text-base leading-relaxed text-gray-600">Les meilleures offres que vous n'aurez nulle part ailleurs.</p>
        </div>
  
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-auto mt-14">
          <div class="overflow-hidden bg-transparent border-2 border-gray-200 rounded-md">
            <div class="p-6 md:py-8 md:px-9">
              <h3 class="text-xl font-semibold text-black">Freemium</h3>
              <p class="mt-2.5 text-sm text-gray-600">Toutes les fonctionnalités de base pour booster votre carrière de freelance</p>
              <div class="flex items-end mt-5">
                <div class="flex items-start">
                  <span class="text-xl font-medium text-black"> $ </span>
                  <p class="text-6xl font-medium tracking-tight">39</p>
                </div>
                <span class="ml-0.5 text-lg text-gray-600"> / mois </span>
              </div>
              <a href="#" class="inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold text-gray-700 transition-all duration-200 bg-transparent border-2 rounded-full border-fuchsia-600 hover:bg-fuchsia-600 hover:text-white focus:text-white focus:bg-fuchsia-600">Commencez l'essai gratuit de 14 jours</a>
              <ul class="flex flex-col mt-8 space-y-4">
                <li class="inline-flex items-center space-x-2">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-base font-medium text-gray-900"> 1 Licence de domaine </span>
                </li>
                <li class="inline-flex items-center space-x-2">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-base font-medium text-gray-900"> Bibliothèque de célébration complète </span>
                </li>
                <li class="inline-flex items-center space-x-2">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-base font-medium text-gray-900"> 120+ Blocs codés </span>
                </li>
              </ul>
            </div>
          </div>
  
          <div class="overflow-hidden bg-white border-2 border-transparent rounded-md">
            <div class="p-6 md:py-8 md:px-9">
              <h3 class="text-xl font-semibold text-black">Standard</h3>
              <p class="mt-2.5 text-sm text-gray-600">Toutes les fonctionnalités étendues pour booster votre carrière d'agence</p>
              <div class="flex items-end mt-5">
                <div class="flex items-start">
                  <span class="text-xl font-medium text-black"> $ </span>
                  <p class="text-6xl font-medium tracking-tight">99</p>
                </div>
                <span class="ml-0.5 text-lg text-gray-600"> / mois </span>
              </div>
              <a href="#" class="inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold text-white transition-all duration-200 border-2 border-transparent rounded-full bg-gradient-to-r from-fuchsia-600 to-blue-600 hover:opacity-80 focus:opacity-80">Commencez l'essai gratuit de 14 jours</a>
              <ul class="flex flex-col mt-8 space-y-4">
                <li class="inline-flex items-center space-x-2">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-base font-medium text-gray-900"> 100 Licences de domaine </span>
                </li>
                <li class="inline-flex items-center space-x-2">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-base font-medium text-gray-900"> Bibliothèque de célébration complète </span>
                </li>
                <li class="inline-flex items-center space-x-2">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-base font-medium text-gray-900"> 120+ Blocs codés </span>
                </li>
              </ul>
            </div>
          </div>
  
          <div class="overflow-hidden bg-white border-2 border-transparent rounded-md">
            <div class="p-6 md:py-8 md:px-9">
              <h3 class="text-xl font-semibold text-black">Premium</h3>
              <p class="mt-2.5 text-sm text-gray-600">Toutes les fonctionnalités avancées pour faire croître votre entreprise</p>
              <div class="flex items-end mt-5">
                <div class="flex items-start">
                  <span class="text-xl font-medium text-black"> $ </span>
                  <p class="text-6xl font-medium tracking-tight">179</p>
                </div>
                <span class="ml-0.5 text-lg text-gray-600"> / mois </span>
              </div>
              <a href="#" class="inline-flex items-center justify-center w-full px-4 py-3 mt-6 font-semibold text-white transition-all duration-200 border-2 border-transparent rounded-full bg-gradient-to-r from-green-600 to-teal-600 hover:opacity-80 focus:opacity-80">Commencez l'essai gratuit de 14 jours</a>
              <ul class="flex flex-col mt-8 space-y-4">
                <li class="inline-flex items-center space-x-2">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-base font-medium text-gray-900"> 500 Licences de domaine </span>
                </li>
                <li class="inline-flex items-center space-x-2">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-base font-medium text-gray-900"> Bibliothèque de célébration complète </span>
                </li>
                <li class="inline-flex items-center space-x-2">
                  <svg class="flex-shrink-0 w-5 h-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                  <span class="text-base font-medium text-gray-900"> 120+ Blocs codés </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  

<script>
export default {

}
</script>

<style>

</style>
