import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import '../public/style.css'
import router from './router'
import store from './store'

// Importation de vue3-toastify et de ses styles
import Vue3Toastify from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

// Création de l'application et ajout des plugins
createApp(App)
  .use(store)
  .use(router)
  .use(Vue3Toastify, {
    autoClose: 3000,  // Durée avant fermeture automatique de la notification
    hideProgressBar: false,  // Afficher ou non la barre de progression
    pauseOnHover: true,  // Mettre en pause lorsqu'on survole la notification
    draggable: true,  // Permet de déplacer la notification
    closeButton: true,  // Afficher le bouton de fermeture
    position: 'top-right',  // Position de la notification
  })
  .mount('#app');
