import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Alert from "../views/Alert.vue";
import Login from "../views/Login.vue";
import Pricing from "@/views/Pricing.vue";
import Privacy from "@/views/Privacy.vue";
import Blog from "@/views/Blog.vue";
import Signup from "@/views/Signup.vue";
import TermeCondition from "@/views/TermeCondition.vue";
import GenerateBadge from "@/views/badges/GenerateBadge.vue";
import AllBadges from "@/views/badges/AllBadges.vue";
import Espace from "@/views/dashboard/Espace.vue";
import Article from "@/views/articles/Article.vue";
import RessetPassword from "@/views/RessetPassword.vue";
import Ticket from "@/views/Ticket.vue";
import WhatsAppApi from "@/views/dashboard/WhatsAppApi.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path: "/alert",
    name: "alert",
    component: Alert,
  },

  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },

  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/resset-password",
    name: "resset-password",
    component: RessetPassword,
  },

  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },

  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
  },

  {
    path: "/term-condition",
    name: "term-condition",
    component: TermeCondition,
  },

  {
    path: "/blogs",
    name: "blogs",
    component: Blog,
  },

  {
    path: "/jyserai/badges",
    name: "jyserai/badges",
    component: AllBadges,
  },

  {
    path: "/dashboard/espace",
    name: "espace",
    component: Espace,
  },

  {
    path: "/dashboard/whatsapp-api",
    name: "whatsapp-api",
    component: WhatsAppApi,
  },

  {
    path: "/badges/generate-badges/jyserai/:id",
    name: "generate-badges",
    component: GenerateBadge,
  },

  {
    path: "/articles/article",
    name: "article",
    component: Article,
  },

  {
    path: "/ticket",
    name: "ticket",
    component: Ticket,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
