<template>
<div>
    <div class="bg-white">
        <Header />
        <section class="bg-[#FCF8F1] bg-opacity-30 py-10 sm:py-16 lg:py-24">
            <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div class="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
                    <div>
                        <h1 class="mt-4 text-4xl font-bold text-black lg:mt-8 sm:text-6xl xl:text-8xl">Un badge👀! une promo assurée🎉</h1>
                        <a href="/login" title="" class="inline-flex items-center px-6 py-4 mt-8 font-semibold text-black transition-all duration-200 bg-yellow-300 rounded-full lg:mt-16 hover:bg-yellow-400 focus:bg-yellow-400" role="button">
                            Soumettre mon badge
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                            </svg>
                        </a>
                    </div>
                    <div>
                        <img class="w-full " src="/img/J’y serai.gif" alt="j'y serai" />
                    </div>
                </div>
            </div>
        </section>
    </div>
    <LatestBadges />
    <Feature />
    <Testinomial />
    <Footer />
</div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import LatestBadges from '@/components/LatestBadges.vue';
import Feature from '@/components/Feature.vue';
import Testinomial from '@/components/Testinomial.vue';

export default {
    components: {
        Header,
        Footer,
        LatestBadges,
        Feature,
        Testinomial
    },
};
</script>

<style>

</style>
