<template>
    <section>
        <!-- Title Container -->
        <div class="flex h-auto w-full flex-col items-center justify-center bg-gray-100 py-6 md:h-64">
            <div class="flex flex-col items-center gap-y-4 py-5">
                <h1 class="text-4xl font-bold md:text-6xl">CONDITIONS D'UTILISATION</h1>
                <p class="text-sm text-gray-500 sm:text-base"> Dernière mise à jour : 17 octobre 2022 </p>
            </div>
        </div>
        <div class="flex flex-col items-center justify-center px-5 md:px-10">
            <!-- Content Container -->
            <div class="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
                <!-- Component -->
                <div class="flex flex-col items-center gap-y-14">
                    <p class="max-w-3xl text-center text-sm sm:text-base">Bienvenue sur Mougni, une plateforme en ligne pour la création et la gestion de badges. En utilisant cette plateforme, vous acceptez les termes et conditions suivants. </p>
                    <div class="flex min-w-full flex-col gap-y-10">
                        <!-- General Terms -->
                        <div class="flex min-w-full py-4 border-b border-gray-300">
                            <h6 class="text-base font-bold">CONDITIONS GÉNÉRALES</h6>
                        </div>
                        <div class="flex flex-col gap-y-10">
                            <div class="flex min-w-full flex-col items-start gap-y-6">
                                <!-- Services -->
                                <div class="flex flex-col items-start gap-y-3">
                                    <p class="text-sm font-bold">SERVICES PROPOSÉS</p>
                                    <p class="text-sm">Mougni propose des services de création, personnalisation, et gestion de badges numériques pour divers événements et entreprises. Les fonctionnalités peuvent inclure des options de design, la gestion des participants et la génération de QR codes.</p>
                                </div>
                                <!-- Responsabilités de l'utilisateur -->
                                <div class="flex flex-col items-start gap-y-3">
                                    <p class="text-sm font-bold">RESPONSABILITÉS DE L'UTILISATEUR</p>
                                    <p class="text-sm">L'utilisateur est responsable de fournir des informations exactes et à jour lors de la création de badges. Toute utilisation abusive, comme la fausse représentation ou l'infraction de droits tiers, est strictement interdite et peut entraîner la suspension de l'accès à la plateforme.</p>
                                </div>
                                <!-- Propriété intellectuelle -->
                                <div class="flex flex-col items-start gap-y-3">
                                    <p class="text-sm font-bold">PROPRIÉTÉ INTELLECTUELLE</p>
                                    <p class="text-sm">Tous les droits de propriété intellectuelle liés aux designs et aux éléments créés via Mougni appartiennent à l'utilisateur, à moins qu'il ne soit explicitement indiqué que Mougni détient certains droits sur les modèles de design fournis. </p>
                                </div>
                                <!-- Paiement et facturation -->
                                <div class="flex flex-col items-start gap-y-3">
                                    <p class="text-sm font-bold">PAIEMENT ET FACTURATION</p>
                                    <p class="text-sm">Les utilisateurs doivent régler les frais applicables pour accéder aux services premium de Mougni. Les prix sont affichés sur la plateforme et peuvent être modifiés sans préavis. Le non-paiement des frais peut entraîner la suspension de l'accès aux fonctionnalités premium.</p>
                                </div>
                                <!-- Confidentialité -->
                                <div class="flex flex-col items-start gap-y-3">
                                    <p class="text-sm font-bold">CONFIDENTIALITÉ</p>
                                    <p class="text-sm">Mougni s'engage à respecter la confidentialité des informations fournies par l'utilisateur. Aucune donnée personnelle ou professionnelle ne sera partagée sans le consentement explicite de l'utilisateur, sauf en cas de demande légale.</p>
                                </div>
                                <!-- Limitation de responsabilité -->
                                <div class="flex flex-col items-start gap-y-3">
                                    <p class="text-sm font-bold">LIMITATION DE RESPONSABILITÉ</p>
                                    <p class="text-sm">Mougni ne peut être tenu responsable des dommages indirects, consécutifs ou spéciaux résultant de l'utilisation ou de l'incapacité à utiliser les services. La responsabilité de Mougni est limitée au montant payé par l'utilisateur pour accéder aux services concernés.</p>
                                </div>
                                <!-- Résiliation -->
                                <div class="flex flex-col items-start gap-y-3">
                                    <p class="text-sm font-bold">RÉSILIATION</p>
                                    <p class="text-sm">Mougni se réserve le droit de résilier ou suspendre l'accès à la plateforme si l'utilisateur viole les présentes conditions d'utilisation. Toute résiliation ne libère pas l'utilisateur de ses obligations financières pour les services déjà rendus.</p>
                                </div>
                                <!-- Loi applicable -->
                                <div class="flex flex-col items-start gap-y-3">
                                    <p class="text-sm font-bold">LOI APPLICABLE</p>
                                    <p class="text-sm">Les présentes conditions d'utilisation sont régies par les lois du [votre juridiction]. Tout litige sera soumis à la compétence exclusive des tribunaux du [votre juridiction].</p>
                                </div>
                            </div>
                            <div class="min-h-[1px] min-w-full bg-gray-300"></div>
                            <p class="text-sm">En accédant ou en utilisant Mougni, vous acceptez d'être lié par ces conditions d'utilisation. Si vous avez des questions ou des préoccupations, veuillez nous contacter avant d'utiliser notre service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </template>
    
    <script>
    export default {
    
    }
    </script>
    
    <style>
    </style>
    