<template>
  <router-view />
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  mounted() {
    // Exemple de notification au montage du composant
  // toast("Bienvenue sur l'application !", {
  //   autoClose: 3000,
  //  position: toast.POSITION.BOTTOM_RIGHT,
  // });
  },
};
</script>

<style scoped>
/* Ajoutez ici des styles si nécessaire */
</style>
