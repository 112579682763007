<template>
<section>
    <div class="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
        <h2 class="text-center text-3xl font-bold md:text-5xl">J'y serai</h2>
        <div class="mt-6">
            <!-- Grille défilante avec chevrons sur mobile -->
            <div class="relative flex items-center justify-center pb-4 sm:hidden">
                <!-- Chevron gauche -->
                <button @click="prevBadge" class="absolute left-0 text-gray-700 bg-white p-2 rounded-full shadow-lg hover:bg-gray-200 focus:outline-none" v-if="currentBadgeIndex > 0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                </button>

                <!-- Badge actuel -->
                <div v-if="badges.length" class="flex-shrink-0 max-w-sm flex-col items-center gap-4 rounded-lg border border-gray-200 bg-white shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
                    <!-- Image -->
                    <a :href="'/badges/generate-badges/' + badges[currentBadgeIndex].badge.id" class="mb-4 inline-block h-52 w-full overflow-hidden rounded-lg">
                        <img :src="BACK_URL + badges[currentBadgeIndex].badge.badge" alt="" class="h-full w-full object-cover rounded-lg transition-transform duration-300 transform hover:scale-105" />
                    </a>

                    <!-- Badge Name -->
                    <p class="font-bold text-xl text-gray-800">{{ badges[currentBadgeIndex].badge.name }}</p>

                    <!-- Views and Downloads -->
                    <div class="text-sm text-gray-500">
                        <span>Vues: {{ badges[currentBadgeIndex].badge.views }}</span> |
                        <span>Télechargements: {{ badges[currentBadgeIndex].badge.downloads }}</span>
                    </div>

                    <!-- Button to view badge -->
                    <a :href="'/badges/generate-badges/' + badges[currentBadgeIndex].badge.id" class="inline-flex items-center px-6 py-2 font-semibold text-black transition-all duration-200 bg-yellow-500 rounded-full hover:bg-yellow-600 focus:bg-yellow-600 mt-4">
                        Voir le Badge
                        <svg class="w-6 h-6 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </a>
                </div>

                <!-- Chevron droit -->
                <button @click="nextBadge" class="absolute right-0 text-gray-700 bg-white p-2 rounded-full shadow-lg hover:bg-gray-200 focus:outline-none" v-if="currentBadgeIndex < badges.length - 1">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>

            <!-- Grille statique pour les écrans plus grands -->
            <div class="hidden sm:grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                <div v-for="badge in badges" :key="badge.badge.id" class="flex flex-col items-center gap-4 rounded-lg border border-gray-200 bg-white shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
                    <a :href="'/badges/generate-badges/' + 'jyserai/' + badge.badge.id" class="mb-4 inline-block h-52 w-full overflow-hidden rounded-lg">
                        <img :src="BACK_URL + badge.badge.badge" alt="" class="h-full w-full object-cover rounded-lg transition-transform duration-300 transform hover:scale-105" />
                    </a>
                    <p class="font-bold text-xl text-gray-800">
                        {{ badge.badge.name.length > 16 ? badge.badge.name.slice(0, 16) + '...' : badge.badge.name }}
                    </p>

                    <div class="text-sm text-gray-500">
                        <span>Vues: {{ badge.badge.views }}</span> |
                        <span>Télechargements: {{ badge.badge.downloads }}</span>
                    </div>
                    <a :href="'/badges/generate-badges/' + 'jyserai/' + badge.badge.id" class="inline-flex items-center px-6 py-2 font-semibold text-black transition-all duration-200 bg-yellow-500 rounded-full hover:bg-yellow-600 focus:bg-yellow-600 mt-4">
                        Voir le Badge 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import axios from "@/api/axios.js";
import {
    BACK_URL
} from "@/event_badge.js";

export default {
    data() {
        return {
            badges: [],
            BACK_URL: BACK_URL,
            currentBadgeIndex: 0,
        };
    },
    mounted() {
        this.fetchLatestBadges();
    },
    methods: {
        fetchLatestBadges() {
            axios
                .get("/latest-badges")
                .then((response) => {
                    this.badges = response.data.badges;
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération des badges :", error);
                });
        },
        prevBadge() {
            if (this.currentBadgeIndex > 0) {
                this.currentBadgeIndex -= 1;
            }
        },
        nextBadge() {
            if (this.currentBadgeIndex < this.badges.length - 1) {
                this.currentBadgeIndex += 1;
            }
        },
    },
};
</script>
