<template>
    <section>
        <!-- Title Container -->
        <div class="flex h-auto w-full flex-col items-center justify-center bg-gray-100 py-6 md:h-64">
            <div class="flex flex-col items-center gap-y-4 py-5">
                <h1 class="text-4xl font-bold md:text-6xl">CONDITIONS D'UTILISATION</h1>
                <p class="text-sm text-gray-500 sm:text-base"> Dernière mise à jour : 5 novembre 2024 </p>
            </div>
        </div>
        <div class="flex flex-col items-center justify-center px-5 md:px-10">
            <!-- Content Container -->
            <div class="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
                <!-- Component -->
                <div class="flex flex-col items-center gap-y-14">
                    <p class="max-w-3xl text-center text-sm sm:text-base"> Bienvenue sur Mougni ! En utilisant notre plateforme, vous acceptez les termes et conditions d'utilisation suivants. Veuillez les lire attentivement. </p>
                    
                    <div class="flex min-w-full flex-col gap-y-10">
                        <div class="flex min-w-full py-4 border-b border-gray-300">
                            <h6 class="text-base font-bold"> CONDITIONS GÉNÉRALES </h6>
                        </div>
                        
                        <div class="flex flex-col gap-y-10">
                            <!-- Section: Services -->
                            <div class="flex flex-col items-start gap-y-6">
                                <p class="text-sm font-bold">SERVICES</p>
                                <p class="text-sm"> Mougni fournit une plateforme en ligne pour la création et la gestion de badges personnalisés. Nous nous réservons le droit de modifier ou d'interrompre tout aspect de notre service à tout moment. </p>
                            </div>
                            
                            <!-- Section: Responsabilités de l'Utilisateur -->
                            <div class="flex flex-col items-start gap-y-6">
                                <p class="text-sm font-bold">RESPONSABILITÉS DE L'UTILISATEUR</p>
                                <p class="text-sm"> En tant qu'utilisateur, vous vous engagez à fournir des informations exactes et à jour et à ne pas utiliser la plateforme pour des activités illégales ou inappropriées. Vous êtes responsable du contenu des badges que vous créez et vous vous engagez à respecter les droits d'auteur et les marques déposées. </p>
                            </div>
                            
                            <!-- Section: Propriété Intellectuelle -->
                            <div class="flex flex-col items-start gap-y-6">
                                <p class="text-sm font-bold">PROPRIÉTÉ INTELLECTUELLE</p>
                                <p class="text-sm"> Tout le contenu généré sur la plateforme, y compris les designs et les éléments graphiques, est protégé par les lois sur la propriété intellectuelle. Mougni conserve tous les droits sur ses propres éléments et outils de design. Les badges créés par les utilisateurs restent leur propriété. </p>
                            </div>
                            
                            <!-- Section: Paiement -->
                            <div class="flex flex-col items-start gap-y-6">
                                <p class="text-sm font-bold">PAIEMENT</p>
                                <p class="text-sm"> Les utilisateurs doivent payer les frais applicables pour les fonctionnalités et services premium de la plateforme. Les tarifs et modalités de paiement sont disponibles sur notre site, et nous nous réservons le droit de modifier ces tarifs. </p>
                            </div>
                            
                            <!-- Section: Confidentialité -->
                            <div class="flex flex-col items-start gap-y-6">
                                <p class="text-sm font-bold">CONFIDENTIALITÉ</p>
                                <p class="text-sm"> Nous nous engageons à protéger la confidentialité des données de nos utilisateurs. Veuillez consulter notre politique de confidentialité pour en savoir plus sur la manière dont nous collectons, utilisons et protégeons vos informations personnelles. </p>
                            </div>
                            
                            <!-- Section: Limitation de Responsabilité -->
                            <div class="flex flex-col items-start gap-y-6">
                                <p class="text-sm font-bold">LIMITATION DE RESPONSABILITÉ</p>
                                <p class="text-sm"> Mougni ne peut être tenu responsable des dommages indirects ou imprévus résultant de l'utilisation ou de l'impossibilité d'utiliser la plateforme. Notre responsabilité se limite aux montants payés par l'utilisateur pour l'accès aux services payants. </p>
                            </div>
                            
                            <!-- Section: Résiliation -->
                            <div class="flex flex-col items-start gap-y-6">
                                <p class="text-sm font-bold">RÉSILIATION</p>
                                <p class="text-sm"> Mougni se réserve le droit de suspendre ou de résilier l'accès d'un utilisateur en cas de non-respect des présentes conditions. Les utilisateurs peuvent également mettre fin à leur utilisation de la plateforme à tout moment. </p>
                            </div>
                            
                            <!-- Section: Loi Applicable -->
                            <div class="flex flex-col items-start gap-y-6">
                                <p class="text-sm font-bold">LOI APPLICABLE</p>
                                <p class="text-sm"> Ces conditions sont régies par les lois de [votre juridiction]. Tout litige sera soumis à la juridiction exclusive des tribunaux de [votre juridiction]. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </template>
    
    <script>
    export default {
    
    }
    </script>
    
    <style>
    
    </style>
    