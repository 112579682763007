<template>
<section class="py-10 bg-gray-100 sm:py-16 lg:py-24">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Témoignages de nos clients</h2>
        </div>
        <div class="grid grid-cols-1 gap-6 px-4 mt-12 sm:px-0 xl:mt-20 xl:grid-cols-4 sm:grid-cols-2">
            <div class="overflow-hidden bg-white rounded-md">
                <div class="px-5 py-6">
                    <div class="flex items-center justify-between">
                        <img class="flex-shrink-0 object-cover w-10 h-10 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/7/avatar-1.jpg" alt="" />
                        <div class="min-w-0 ml-3 mr-auto">
                            <p class="text-base font-semibold text-black truncate">Marc Dubois</p>
                            <p class="text-sm text-gray-600 truncate">@marc_dubois</p>
                        </div>
                        <a href="#" title="" class="inline-block text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#25D366">
                                <path d="M12.02 2c-5.52 0-10 4.48-10 10 0 1.75.46 3.47 1.35 5.01L2 22l4.99-1.33c1.5.87 3.21 1.33 4.96 1.33 5.52 0 10-4.48 10-10s-4.48-10-10-10zm5.14 14.36c-.21.6-1.21 1.15-1.69 1.18-.44.03-.84.21-2.74-.58-2.3-.9-3.74-3.21-3.86-3.35-.11-.15-.92-1.22-.92-2.34 0-1.11.58-1.66.79-1.88.21-.22.45-.28.6-.28.15 0 .3.01.43.01.14.01.33-.05.51.39.19.44.66 1.57.72 1.68.06.11.1.24.02.39-.07.15-.11.23-.22.36-.11.13-.22.28-.32.38-.11.11-.23.23-.1.45.14.22.62 1.03 1.32 1.66.91.82 1.67 1.07 1.89 1.18.22.11.35.09.48-.05.13-.14.54-.63.69-.85.15-.22.29-.19.48-.11.2.08 1.27.6 1.48.71.22.11.36.16.42.25.05.08.05.59-.16 1.19z" />
                            </svg>
                        </a>
                    </div>
                    <blockquote class="mt-5">
                        <p class="text-base text-gray-800">
                            Grâce à ces badges "J'y serai", l'enthousiasme de nos invités pour notre mariage a été incroyable ! Tout le monde était ravi de participer.
                            <span class="block text-sky-500">#MariageInoubliable</span>
                        </p>
                    </blockquote>
                </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md">
                <div class="px-5 py-6">
                    <div class="flex items-center justify-between">
                        <img class="flex-shrink-0 object-cover w-10 h-10 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/7/avatar-2.jpg" alt="" />
                        <div class="min-w-0 ml-3 mr-auto">
                            <p class="text-base font-semibold text-black truncate">Sophie Lefevre</p>
                            <p class="text-sm text-gray-600 truncate">@sophie_lefevre</p>
                        </div>
                        <a href="#" title="" class="inline-block text-sky-500">
                            <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                            </svg>
                        </a>
                    </div>
                    <blockquote class="mt-5">
                        <p class="text-base text-gray-800">
                            Le badge "J'y serai" a donné une touche personnelle et festive à notre concert. Nos invités se sont sentis connectés à l'événement avant même d'y arriver.
                            <span class="block text-sky-500">#ConcertInoubliable</span>
                        </p>
                    </blockquote>
                </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md">
                <div class="px-5 py-6">
                    <div class="flex items-center justify-between">
                        <img class="flex-shrink-0 object-cover w-10 h-10 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/7/avatar-3.jpg" alt="" />
                        <div class="min-w-0 ml-3 mr-auto">
                            <p class="text-base font-semibold text-black truncate">Alice Martin</p>
                            <p class="text-sm text-gray-600 truncate">@alice_martin</p>
                        </div>
                        <a href="#" title="" class="inline-block text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#1877F2">
                                <path d="M22.675 0H1.325C.593 0 0 .592 0 1.324v21.351C0 23.408.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.894-4.788 4.658-4.788 1.325 0 2.463.099 2.794.143v3.24h-1.917c-1.504 0-1.797.715-1.797 1.764v2.313h3.59l-.467 3.622h-3.123V24h6.116C23.407 24 24 23.407 24 22.674V1.324C24 .592 23.407 0 22.675 0z" />
                            </svg>
                        </a>
                    </div>
                    <blockquote class="mt-5">
                        <p class="text-base text-gray-800">
                            La personnalisation de ces badges a vraiment ajouté un effet wow à notre conférence, les participants ont été super engagés avant même de commencer !
                            <span class="block text-sky-500">#ConférenceMagique</span>
                        </p>
                    </blockquote>
                </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md">
                <div class="px-5 py-6">
                    <div class="flex items-center justify-between">
                        <img class="flex-shrink-0 object-cover w-10 h-10 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/7/avatar-4.jpg" alt="" />
                        <div class="min-w-0 ml-3 mr-auto">
                            <p class="text-base font-semibold text-black truncate">CAM CAM Bargue</p>
                            <p class="text-sm text-gray-600 truncate">@camcam_bargue</p>
                        </div>
                        <a href="#" title="" class="inline-block text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#25D366">
                                <path d="M12.02 2c-5.52 0-10 4.48-10 10 0 1.75.46 3.47 1.35 5.01L2 22l4.99-1.33c1.5.87 3.21 1.33 4.96 1.33 5.52 0 10-4.48 10-10s-4.48-10-10-10zm5.14 14.36c-.21.6-1.21 1.15-1.69 1.18-.44.03-.84.21-2.74-.58-2.3-.9-3.74-3.21-3.86-3.35-.11-.15-.92-1.22-.92-2.34 0-1.11.58-1.66.79-1.88.21-.22.45-.28.6-.28.15 0 .3.01.43.01.14.01.33-.05.51.39.19.44.66 1.57.72 1.68.06.11.1.24.02.39-.07.15-.11.23-.22.36-.11.13-.22.28-.32.38-.11.11-.23.23-.1.45.14.22.62 1.03 1.32 1.66.91.82 1.67 1.07 1.89 1.18.22.11.35.09.48-.05.13-.14.54-.63.69-.85.15-.22.29-.19.48-.11.2.08 1.27.6 1.48.71.22.11.36.16.42.25.05.08.05.59-.16 1.19z" />
                            </svg>
                        </a>
                    </div>
                    <blockquote class="mt-5">
                        <p class="text-base text-gray-800">
                            Nous avons pu suivre l'enthousiasme de notre public avant notre brunch, grâce aux badges personnalisés qui ont engagé tout le monde !
                            <span class="block text-sky-500">#BrunchInoubliable</span>
                        </p>
                    </blockquote>
                </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md">
                <div class="px-5 py-6">
                    <div class="flex items-center justify-between">
                        <img class="flex-shrink-0 object-cover w-10 h-10 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/7/avatar-5.jpg" alt="" />
                        <div class="min-w-0 ml-3 mr-auto">
                            <p class="text-base font-semibold text-black truncate">Éléonore Gauthier</p>
                            <p class="text-sm text-gray-600 truncate">@eleonore_gauthier</p>
                        </div>
                        <a href="#" title="" class="inline-block text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#25D366">
                                <path d="M12.02 2c-5.52 0-10 4.48-10 10 0 1.75.46 3.47 1.35 5.01L2 22l4.99-1.33c1.5.87 3.21 1.33 4.96 1.33 5.52 0 10-4.48 10-10s-4.48-10-10-10zm5.14 14.36c-.21.6-1.21 1.15-1.69 1.18-.44.03-.84.21-2.74-.58-2.3-.9-3.74-3.21-3.86-3.35-.11-.15-.92-1.22-.92-2.34 0-1.11.58-1.66.79-1.88.21-.22.45-.28.6-.28.15 0 .3.01.43.01.14.01.33-.05.51.39.19.44.66 1.57.72 1.68.06.11.1.24.02.39-.07.15-.11.23-.22.36-.11.13-.22.28-.32.38-.11.11-.23.23-.1.45.14.22.62 1.03 1.32 1.66.91.82 1.67 1.07 1.89 1.18.22.11.35.09.48-.05.13-.14.54-.63.69-.85.15-.22.29-.19.48-.11.2.08 1.27.6 1.48.71.22.11.36.16.42.25.05.08.05.59-.16 1.19z" />
                            </svg>
                        </a>
                    </div>
                    <blockquote class="mt-5">
                        <p class="text-base text-gray-800">
                            Le concept de badges personnalisés a été une révélation pour notre conférence, les participants ont adoré la possibilité de se connecter avant l'événement.
                            <span class="block text-sky-500">#ÉvénementRéussi</span>
                        </p>
                    </blockquote>
                </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md">
                <div class="px-5 py-6">
                    <div class="flex items-center justify-between">
                        <img class="flex-shrink-0 object-cover w-10 h-10 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/7/avatar-6.jpg" alt="" />
                        <div class="min-w-0 ml-3 mr-auto">
                            <p class="text-base font-semibold text-black truncate">Cédric Simon</p>
                            <p class="text-sm text-gray-600 truncate">@cedric_simon</p>
                        </div>
                        <a href="#" title="" class="inline-block text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#25D366">
                                <path d="M12.02 2c-5.52 0-10 4.48-10 10 0 1.75.46 3.47 1.35 5.01L2 22l4.99-1.33c1.5.87 3.21 1.33 4.96 1.33 5.52 0 10-4.48 10-10s-4.48-10-10-10zm5.14 14.36c-.21.6-1.21 1.15-1.69 1.18-.44.03-.84.21-2.74-.58-2.3-.9-3.74-3.21-3.86-3.35-.11-.15-.92-1.22-.92-2.34 0-1.11.58-1.66.79-1.88.21-.22.45-.28.6-.28.15 0 .3.01.43.01.14.01.33-.05.51.39.19.44.66 1.57.72 1.68.06.11.1.24.02.39-.07.15-.11.23-.22.36-.11.13-.22.28-.32.38-.11.11-.23.23-.1.45.14.22.62 1.03 1.32 1.66.91.82 1.67 1.07 1.89 1.18.22.11.35.09.48-.05.13-.14.54-.63.69-.85.15-.22.29-.19.48-.11.2.08 1.27.6 1.48.71.22.11.36.16.42.25.05.08.05.59-.16 1.19z" />
                            </svg>
                        </a>
                    </div>
                    <blockquote class="mt-5">
                        <p class="text-base text-gray-800">
                            La personnalisation des badges pour notre événement a permis à chaque participant de se sentir valorisé, avant même d’arriver sur place.
                            <span class="block text-sky-500">#ÉvénementUnique</span>
                        </p>
                    </blockquote>
                </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md">
                <div class="px-5 py-6">
                    <div class="flex items-center justify-between">
                        <img class="flex-shrink-0 object-cover w-10 h-10 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/7/avatar-7.jpg" alt="" />
                        <div class="min-w-0 ml-3 mr-auto">
                            <p class="text-base font-semibold text-black truncate">Marie Robert</p>
                            <p class="text-sm text-gray-600 truncate">@marie_robert</p>
                        </div>
                        <a href="#" title="" class="inline-block text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#25D366">
                                <path d="M12.02 2c-5.52 0-10 4.48-10 10 0 1.75.46 3.47 1.35 5.01L2 22l4.99-1.33c1.5.87 3.21 1.33 4.96 1.33 5.52 0 10-4.48 10-10s-4.48-10-10-10zm5.14 14.36c-.21.6-1.21 1.15-1.69 1.18-.44.03-.84.21-2.74-.58-2.3-.9-3.74-3.21-3.86-3.35-.11-.15-.92-1.22-.92-2.34 0-1.11.58-1.66.79-1.88.21-.22.45-.28.6-.28.15 0 .3.01.43.01.14.01.33-.05.51.39.19.44.66 1.57.72 1.68.06.11.1.24.02.39-.07.15-.11.23-.22.36-.11.13-.22.28-.32.38-.11.11-.23.23-.1.45.14.22.62 1.03 1.32 1.66.91.82 1.67 1.07 1.89 1.18.22.11.35.09.48-.05.13-.14.54-.63.69-.85.15-.22.29-.19.48-.11.2.08 1.27.6 1.48.71.22.11.36.16.42.25.05.08.05.59-.16 1.19z" />
                            </svg>
                        </a>
                    </div>
                    <blockquote class="mt-5">
                        <p class="text-base text-gray-800">
                            Les badges ont rendu notre événement encore plus mémorable ! La pré-évaluation de l'enthousiasme des participants a vraiment ajouté à la magie.
                            <span class="block text-sky-500">#ÉvénementRéussi</span>
                        </p>
                    </blockquote>
                </div>
            </div>

            <div class="overflow-hidden bg-white rounded-md">
                <div class="px-5 py-6">
                    <div class="flex items-center justify-between">
                        <img class="flex-shrink-0 object-cover w-10 h-10 rounded-full" src="https://cdn.rareblocks.xyz/collection/celebration/images/testimonials/7/avatar-7.jpg" alt="" />
                        <div class="min-w-0 ml-3 mr-auto">
                            <p class="text-base font-semibold text-black truncate">Marie Robert</p>
                            <p class="text-sm text-gray-600 truncate">@marie_robert</p>
                        </div>
                        <a href="#" title="" class="inline-block text-sky-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#25D366">
                                <path d="M12.02 2c-5.52 0-10 4.48-10 10 0 1.75.46 3.47 1.35 5.01L2 22l4.99-1.33c1.5.87 3.21 1.33 4.96 1.33 5.52 0 10-4.48 10-10s-4.48-10-10-10zm5.14 14.36c-.21.6-1.21 1.15-1.69 1.18-.44.03-.84.21-2.74-.58-2.3-.9-3.74-3.21-3.86-3.35-.11-.15-.92-1.22-.92-2.34 0-1.11.58-1.66.79-1.88.21-.22.45-.28.6-.28.15 0 .3.01.43.01.14.01.33-.05.51.39.19.44.66 1.57.72 1.68.06.11.1.24.02.39-.07.15-.11.23-.22.36-.11.13-.22.28-.32.38-.11.11-.23.23-.1.45.14.22.62 1.03 1.32 1.66.91.82 1.67 1.07 1.89 1.18.22.11.35.09.48-.05.13-.14.54-.63.69-.85.15-.22.29-.19.48-.11.2.08 1.27.6 1.48.71.22.11.36.16.42.25.05.08.05.59-.16 1.19z" />
                            </svg>
                        </a>
                    </div>
                    <blockquote class="mt-5">
                        <p class="text-base text-gray-800">
                            La réactivité de l'équipe pour résoudre les petits soucis techniques a été exemplaire. Une expérience parfaite de bout en bout !
                            <span class="block text-sky-500">#SupportRapide</span>
                        </p>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
