<template>
    <div>
      <button @click="showNotification">Afficher Notification</button>
    </div>
  </template>
  
  <script>
  import { toast } from 'vue3-toastify';
  
  export default {
    methods: {
      showNotification() {
        toast("Notification depuis Home.vue !", {
          autoClose: 3000,
          position: toast.POSITION.BOTTOM_LEFT,
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Styles personnalisés pour ce composant */
  </style>
  