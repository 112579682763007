<template>
<section class="py-10 bg-white sm:py-16 lg:py-24">
    <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div class="grid items-center grid-cols-1 gap-y-12 lg:grid-cols-2 lg:gap-x-24">
            <div>
                <img class="w-full max-w-md mx-auto" src="/img/mougni_target.gif" alt="" />
            </div>

            <div class="text-center lg:text-left">
                <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Boostez la visibilité de vos événements.</h2>
                <p class="mt-6 text-base text-gray-600">Les badges <b>J'y serai</b> maximisent l'engagement de votre public, augmentent la visibilité en ligne, et incitent vos participants à devenir des ambassadeurs actifs de votre événement ou cause.</p>

                <a href="/jyserai/badges" title="Voir tous les badges" class="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-white transition duration-200 bg-blue-600 rounded-md mt-4 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" role="button">
                    Voir tous les badges
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15M12 9l3 3m0 0-3 3m3-3H2.25" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {

}
</script>

<style>
    
    </style>
